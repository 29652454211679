define('ember-js-auth/controllers/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    auth: Ember.inject.service('auth'),

    actions: {
      login: function () {
        this.get('auth').login();
      },
      logout: function () {
        this.get('auth').logout();
      }
    }

  });
});