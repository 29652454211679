define('ember-js-auth/routes/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    auth: Ember.inject.service(),
    beforeModel() {
      this.auth.checkLogin();
    }
  });
});