define('ember-js-auth/controllers/dashboard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    auth: Ember.inject.service('auth'),
    ajax: Ember.inject.service('ajax'),

    occurrences: Ember.A(),
    additionalPeople: 0,
    hours: 2,

    // bank: service(), // get fake data
    init() {
      this._super(...arguments);

      this.get('ajax').request('https://getpantry.cloud/apiv1/pantry/e8c4a94c-0f5b-4cc3-9565-2525c2aaadcb/basket/pokwall-calendar').then(response => {
        // `response` is the data from the server
        for (const occ of response.occurrences) {
          this.get('occurrences').pushObject(Ember.Object.create({
            title: occ.title,
            startsAt: occ.startsAt,
            endsAt: occ.endsAt,
            owner: occ.owner
          }));
        }
      });
    },

    saveOccurences() {
      this.get('ajax').request('https://getpantry.cloud/apiv1/pantry/e8c4a94c-0f5b-4cc3-9565-2525c2aaadcb/basket/pokwall-calendar', {
        method: 'POST',
        contentType: "application/json",
        data: { occurrences: this.get('occurrences') }
      }).catch(({ response, jqXHR, payload }) => console.log(payload, response, jqXHR));
    },

    getUserString() {
      var userString = " + " + this.get('additionalPeople').toString();
      return userString;
    },

    trim: function (name) {
      if (name.indexOf('@') > 0) {
        return name.substring(0, name.indexOf('@'));
      } else {
        return name;
      }
    },

    actions: {
      calendarAddOccurrence: function (occurrence) {
        this.get('occurrences').pushObject(Ember.Object.create({
          title: this.trim(this.auth.user.name) + this.getUserString(),
          startsAt: occurrence.get('startsAt'),
          endsAt: occurrence.get('endsAt').setHours(occurrence.get('endsAt').getHours() + this.get("hours") - 1),
          owner: this.auth.user.name
        }));
        this.saveOccurences();
      },

      calendarAddSession: function (occurrence) {
        this.get('occurrences').pushObject(Ember.Object.create({
          title: this.trim(this.auth.user.name) + this.getUserString(),
          startsAt: new Date(),
          endsAt: new Date().setHours(new Date().getHours() + this.get("hours")),
          owner: this.auth.user.name
        }));
        this.saveOccurences();
      },

      calendarUpdateOccurrence: function (occurrence, properties) {
        //occurrence.setProperties(properties);
        //this.saveOccurences();
      },

      calendarRemoveOccurrence: function (occurrence) {
        if (occurrence.owner === this.auth.user.name || this.auth.user.name == "Peter Multáň") {
          this.get('occurrences').removeObject(occurrence);
          this.saveOccurences();
        }
      },

      addPeople: function () {
        this.set('additionalPeople', this.get('additionalPeople') + 1);
      },

      decreasePeople: function () {
        if (this.get('additionalPeople') > 0) {
          this.set('additionalPeople', this.get('additionalPeople') - 1);
        }
      },

      addHours: function () {
        this.set('hours', this.get('hours') + 0.5);
      },

      decreaseHours: function () {
        if (this.get('hours') > 1) {
          this.set('hours', this.get('hours') - 0.5);
        }
      }

    }
  });
});