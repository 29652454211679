define('ember-js-auth/services/auth', ['exports', 'ember-js-auth/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    /**
     * Configure our auth0 instance
     */
    auth0: Ember.computed(function () {
      return new auth0.WebAuth({
        // setting up the config file will be covered below
        domain: _environment.default.auth0.domain, // domain from auth0
        clientID: _environment.default.auth0.clientId, // clientId from auth0
        redirectUri: _environment.default.auth0.callbackUrl,
        audience: `https://${_environment.default.auth0.domain}/userinfo`,
        responseType: 'token',
        scope: 'openid profile' // adding profile because we want username, given_name, etc
      });
    }),

    /**
     * Send a user over to the hosted auth0 login page
     */
    login() {
      this.get('auth0').authorize();
    },

    /**
     * When a user lands back on our application
     * Parse the hash and store user info
     */
    handleAuthentication() {
      return new Promise(resolve => {
        this.get('auth0').parseHash((err, authResult) => {
          if (err) return false;

          if (authResult && authResult.accessToken) {
            this.setUser(authResult.accessToken);
          }

          return resolve();
        });
      });
    },

    /**
     * Computed to tell if a user is logged in or not
     * @return boolean
     */
    isAuthenticated: Ember.computed(function () {
      return this.get('checkLogin');
    }),

    /**
     * Use the token to set our user
     */
    setUser(token) {
      // once we have a token, we are able to go get the users information
      this.get('auth0').client.userInfo(token, (err, profile) => this.set('user', profile));
      return true;
    },

    /**
     * Check if we are authenticated using the auth0 library's checkSession
     */
    checkLogin() {
      // check to see if a user is authenticated, we'll get a token back
      this.get('auth0').checkSession({}, (err, authResult) => {
        // if we are wrong, stop everything now
        if (err) return err;
        this.setUser(authResult.accessToken);
      });
    },

    /**
     * Get rid of everything in sessionStorage that identifies this user
     */
    logout() {
      this.get('auth0').logout({
        clientID: _environment.default.auth0.clientId,
        //returnTo: 'http://31.187.71.201:8080'
        //returnTo: 'http://localhost:4200'
        returnTo: 'https://pokwall.sk'
      });
    }
  });
});