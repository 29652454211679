define('ember-js-auth/routes/dashboard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    // auth: service('auth'),
    // beforeModel() {
    //   // this is where we check if a user is authenticated
    //   // if not authenticated, kick them to the home page
    //   if (!this.auth.isAuthenticated) {
    //     this.transitionTo('/');
    //   }
    // }
  });
});