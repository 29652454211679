define('ember-js-auth/routes/callback', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),
    beforeModel() {
      // check if we are authenticated
      // parse the url hash that comes back from auth0
      // if authenticated on login, redirect to the dashboard
      Ember.get(this, 'auth').handleAuthentication().then(() => this.transitionTo('/dashboard'));
    }
  });
});