define('ember-js-auth/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    init() {
      this._super(...arguments);
    }
  });
});