define('ember-js-auth/components/app-nav', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    auth: Ember.inject.service('auth'),
    actions: {

      /**
      * From service/auth, starting the login process
      */
      login() {
        this.get('auth').login();
      },

      goHome() {
        this.get('router').transitionTo('home');
      },

      goDashboard() {
        this.get('router').transitionTo('dashboard');
      },

      /**
      * From service/auth, removing the saved token from the session.
      */
      logout() {
        this.get('auth').logout();
      }
    }
  });
});